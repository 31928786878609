@import 'variables';

@mixin red-btn() {
    border-radius: 12px;
    border: 1px solid #c8c8c8;
    background: linear-gradient(to bottom, rgba(253, 94, 110, 1) 0, rgba(247, 82, 103, 1) 18%, rgba(219, 22, 68, 1) 73%, rgba(209, 1, 56, 1) 100%);
    text-decoration: none;
    font: 14px/16px 'Roboto Regular', sans-serif;
    color: $white !important;
    position: relative;
    display: inline-block;
    padding: 15px 30px 15px 37px;
    text-transform: uppercase;
    &:hover {
        background: linear-gradient(to bottom, rgba(203, 17, 66, 1) 0, rgba(209, 2, 57, 1) 2%, rgba(215, 14, 64, 1) 18%, rgba(243, 74, 99, 1) 73%, rgba(253, 93, 110, 1) 98%, rgba(237, 104, 118, 1) 100%);
    }
    &:after {
        content: '';
        position: absolute;
        background: url(/images/icons/ico-14.png) no-repeat;
        width: 18px;
        height: 28px;
        left: 10px;
        top: 9px;
    }
}
@mixin buy-btn(){
    text-decoration: none;
    font: 14px/16px 'RobotoRegular', sans-serif;
    color: #fff!important;
    position: relative;
    display: inline-block;
    padding: 15px 30px 15px 37px;
    text-transform: uppercase;
    background: linear-gradient(to bottom, rgba(253, 94, 110, 1) 0, rgba(247, 82, 103, 1) 18%, rgba(219, 22, 68, 1) 73%, rgba(209, 1, 56, 1) 100%);
    border-radius: 12px;
    border: 1px solid #c8c8c8;
    &:hover{
        background: linear-gradient(to bottom, rgba(203, 17, 66, 1) 0, rgba(209, 2, 57, 1) 2%, rgba(215, 14, 64, 1) 18%, rgba(243, 74, 99, 1) 73%, rgba(253, 93, 110, 1) 98%, rgba(237, 104, 118, 1) 100%);
    }
    &:after{
        content: '';
        position: absolute;
        background: url(/images/icons/ico-14.png) no-repeat;
        width: 18px;
        height: 28px;
        left: 10px;
        top: 9px;
    }
}
@mixin buy-one-click-btn{
    text-decoration: none;
    font: 14px/16px 'RobotoRegular', sans-serif;
    color: #fff !important;
    position: relative;
    display: inline-block;
    padding: 15px 30px 15px 37px;
    text-transform: uppercase;
    border-radius: 12px;
    border: 1px solid #c8c8c8;
    background: linear-gradient(to bottom, rgba(77, 181, 238, 1) 0, rgba(23, 140, 205, 1) 100%);
    &:after{
        content: '';
        position: absolute;
        background: url(/images/icons/ico-14.png) no-repeat;
        width: 18px;
        height: 28px;
        left: 10px;
        top: 9px;
    }
    &:hover{
        background: linear-gradient(to bottom, rgba(23, 140, 205, 1) 0, rgba(77, 181, 238, 1) 100%);
    }
}
@mixin black_btn(){
    display: inline-block;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    border-radius: 7px;
    border: 0;
    padding: 8px 20px;
    color: #fff;
    text-transform: uppercase;
    background: #5b5b5b;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzViNWI1YiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjIzJSIgc3RvcC1jb2xvcj0iIzRjNGM0YyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjczJSIgc3RvcC1jb2xvcj0iIzE5MTkxOSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjkzJSIgc3RvcC1jb2xvcj0iIzA5MDkwOSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwNjA2MDYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, rgba(91, 91, 91, 1) 0, rgba(76, 76, 76, 1) 23%, rgba(25, 25, 25, 1) 73%, rgba(9, 9, 9, 1) 93%, rgba(6, 6, 6, 1) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(91, 91, 91, 1)), color-stop(23%, rgba(76, 76, 76, 1)), color-stop(73%, rgba(25, 25, 25, 1)), color-stop(93%, rgba(9, 9, 9, 1)), color-stop(100%, rgba(6, 6, 6, 1)));
    background: -webkit-linear-gradient(top, rgba(91, 91, 91, 1) 0, rgba(76, 76, 76, 1) 23%, rgba(25, 25, 25, 1) 73%, rgba(9, 9, 9, 1) 93%, rgba(6, 6, 6, 1) 100%);
    background: -o-linear-gradient(top, rgba(91, 91, 91, 1) 0, rgba(76, 76, 76, 1) 23%, rgba(25, 25, 25, 1) 73%, rgba(9, 9, 9, 1) 93%, rgba(6, 6, 6, 1) 100%);
    background: -ms-linear-gradient(top, rgba(91, 91, 91, 1) 0, rgba(76, 76, 76, 1) 23%, rgba(25, 25, 25, 1) 73%, rgba(9, 9, 9, 1) 93%, rgba(6, 6, 6, 1) 100%);
    background: linear-gradient(to bottom, rgba(91, 91, 91, 1) 0, rgba(76, 76, 76, 1) 23%, rgba(25, 25, 25, 1) 73%, rgba(9, 9, 9, 1) 93%, rgba(6, 6, 6, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5b5b5b', endColorstr='#060606', GradientType=0);
    cursor: pointer;
    &:hover{
        text-decoration: none;
        background: #060606;
        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzA2MDYwNiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjclIiBzdG9wLWNvbG9yPSIjMDkwOTA5IiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMjclIiBzdG9wLWNvbG9yPSIjMTkxOTE5IiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iNzclIiBzdG9wLWNvbG9yPSIjNGM0YzRjIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzViNWI1YiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
        background: -moz-linear-gradient(top, rgba(6, 6, 6, 1) 0, rgba(9, 9, 9, 1) 7%, rgba(25, 25, 25, 1) 27%, rgba(76, 76, 76, 1) 77%, rgba(91, 91, 91, 1) 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(6, 6, 6, 1)), color-stop(7%, rgba(9, 9, 9, 1)), color-stop(27%, rgba(25, 25, 25, 1)), color-stop(77%, rgba(76, 76, 76, 1)), color-stop(100%, rgba(91, 91, 91, 1)));
        background: -webkit-linear-gradient(top, rgba(6, 6, 6, 1) 0, rgba(9, 9, 9, 1) 7%, rgba(25, 25, 25, 1) 27%, rgba(76, 76, 76, 1) 77%, rgba(91, 91, 91, 1) 100%);
        background: -o-linear-gradient(top, rgba(6, 6, 6, 1) 0, rgba(9, 9, 9, 1) 7%, rgba(25, 25, 25, 1) 27%, rgba(76, 76, 76, 1) 77%, rgba(91, 91, 91, 1) 100%);
        background: -ms-linear-gradient(top, rgba(6, 6, 6, 1) 0, rgba(9, 9, 9, 1) 7%, rgba(25, 25, 25, 1) 27%, rgba(76, 76, 76, 1) 77%, rgba(91, 91, 91, 1) 100%);
        background: linear-gradient(to bottom, rgba(6, 6, 6, 1) 0, rgba(9, 9, 9, 1) 7%, rgba(25, 25, 25, 1) 27%, rgba(76, 76, 76, 1) 77%, rgba(91, 91, 91, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#060606', endColorstr='#5b5b5b', GradientType=0);
    }
}
