@import "variables";
@import "mixins";

.slider-wrapper {
    margin-bottom: 40px;
    position: relative;
    border-bottom: 1px solid #f32450;

    &:before {

        content: '';
        position: absolute;
        background: url(/images/bg-shadow-01.png) no-repeat;
        width: 1281px;
        height: 26px;
        left: 50%;
        bottom: -26px;
        margin-left: -640px;
    }

    .welcome-slider {
        position: relative;

        .uk-slider-items {
            li {
                width: 100%;

                img {
                    object-fit: contain;
                }
            }
        }

        .slider-nav {
            position: absolute;
            top: calc(50% - 30px);

            span {
                color: $white;
            }
        }

        .nav-prev {
            left: 15%;
        }

        .nav-next {
            right: 15%;
        }

        .uk-dotnav {
            position: absolute;
            bottom: 36px;
            left: 50%;
            transform: translate(-50%, 0);

            li {
                a {
                    background-color: white;
                    border: 1px solid white;
                    width: 11px;
                    height: 11px;
                }

                &.uk-active {
                    a {
                        background-color: $red;
                    }
                }
            }
        }
    }
}
.popular-products-wrapper{
    box-shadow: -1px 3px 5px 0 rgb(166 166 166 / 68%);
    margin-bottom: 30px;
    position: relative;
    padding-bottom: 35px;
    &:before{
        content: '';
        position: absolute;
        background: url(/images/bg-shadow-01.png) no-repeat;
        width: 1281px;
        height: 26px;
        left: 50%;
        bottom: -26px;
        margin-left: -640px;
    }
}
.popular-products {
    .h2 {
        text-align: center;
        text-transform: uppercase;
        font: 26px/26px 'RobotoCondensedRegular', sans-serif;
        color: #060606;
        margin: 0 0 20px;
    }

    .items {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap:  15px 0;
        margin-bottom: 20px;
    }
    .btn-wrapper {
        text-align: center;
    }
    .see-all{
        @include red-btn;
    }
}

@media (max-width: 992px) {
    .popular-products{
        .items{
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
}

@media (max-width: 992px) {
    .slider-wrapper{
        display: none;
    }
}
@media (max-width: 768px) {
    .popular-products{
        .items{
            grid-template-columns: 1fr 1fr ;
        }
    }
    .intro-holder{
        h2{
            font-size: 18px;
            line-height: 1.3;
        }
    }
}
